(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name facilities.newFacility.controller:NewFacilityCtrl
   *
   * @description
   *
   */
  angular
    .module('neo.home.facilities.newFacility')
    .controller('NewFacilityCtrl', NewFacilityCtrl);

  function NewFacilityCtrl(uiGmapGoogleMapApi, uiGmapLogger, towns, townhalls, $scope, Facilities, $mdToast, $filter, $state) {
    var vm = this;
    vm.ctrlName = 'NewFacilityCtrl';

    vm.facility = {
      name: undefined,
      townHall: undefined,
      townName: undefined,
      zipCode: undefined,
      address: undefined,
      latitude: undefined,
      longitude: undefined
    };

    vm.towns = towns;
    vm.townHalls = townhalls;
    if (vm.townHalls.length === 1) {
      vm.facility.townHall = vm.townHalls[0];
    }

    vm.submitFacility = function () {
      vm.facility.idTownHall = vm.facility.townHall.id;
      Facilities.save(vm.facility, function () {
          $mdToast.show(
            $mdToast.simple()
              .textContent($filter('translate')('FACILITIES.SUCCESSFUL_CREATE'))
              .position('bottom left')
              .hideDelay(3000));
          $state.go('home.facilities', {}, {reload: true});

        },
        function (error) {
          var message;
          if (error.status === 404) {
            message = 'ERROR_NON_EXISTING_TOWNHALL';
          } else {
            message = 'ERROR_BAD_REQUEST';
          }
          $mdToast.show(
            $mdToast.simple()
              .textContent($filter('translate')(message))
              .position('bottom left')
              .hideDelay(3000)
          );
        });
    };

    vm.markerCoords = {
      latitude: 41.3794631,
      longitude: 2.0568846
    };

    uiGmapGoogleMapApi.then(function (maps) {
      maps.visualRefresh = true;
      uiGmapLogger.log('Map succeed');
      vm.geocoder = new maps.Geocoder();
    });


    vm.map = {
      center: {
        latitude: vm.markerCoords.latitude,
        longitude: vm.markerCoords.longitude
      },
      zoom: 12,
      options: {
        tilt: 45
      },
      events: {
        click: function (mapModel, eventName, eventArgs) {
          setCoordinates(eventArgs[0].latLng.lat(), eventArgs[0].latLng.lng());
        }
      },
      markers: [{
        id: 1,
        latitude: vm.markerCoords.latitude,
        longitude: vm.markerCoords.longitude
      }
      ]
    };

    function centerMap() {
      vm.map.center.latitude = vm.markerCoords.latitude;
      vm.map.center.longitude = vm.markerCoords.longitude;
    }

    vm.searchLocation = function () {
      if (!angular.isUndefined(vm.facility.address) && !angular.isUndefined(vm.facility.townName)) {
        var address = vm.facility.address + ",";
        if (!angular.isUndefined(vm.facility.zipCode)) {
          address = address + vm.facility.zipCode;
        }
        address = address + " " + vm.facility.townName;
        vm.geocoder.geocode({'address': address}, function (results) {
          if (!results.isUndefined || results.size() > 0) {
            setCoordinates(results[0].geometry.location.lat(), results[0].geometry.location.lng());
            centerMap();
          }
        })
      }
    };

    function setCoordinates(latitude, longitude) {
      vm.markerCoords.latitude = vm.facility.latitude = latitude;
      vm.markerCoords.longitude = vm.facility.longitude = longitude;
      vm.map.markers.pop();
      vm.map.markers.push({
        id: 1,
        latitude: vm.markerCoords.latitude,
        longitude: vm.markerCoords.longitude
      });
      $scope.$evalAsync();
    }

  }
}());
